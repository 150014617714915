.PopUpModal {
    width: 100%;
    background: rgba(61, 76, 99, 0.5);

    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.PopUpModal textarea {
    overflow: hidden;
}

.content {
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.170509);
    border-radius: 16px;
    padding: 32px;
    position: relative;
    margin: 16px;
    max-height: 90vh;
    max-width: 85%;
    width: 1200px;
    height: 580px;
}

.btnClose {
    width: 32px;
    height: 32px;
    background: #1c2028;
    border-radius: 50%;
    position: absolute;
    right: -15px;
    top: -15px;
    color: #fff;
    font-size: 40px;
    line-height: 1;
    cursor: pointer;
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .5s;
}
.btnClose:hover{
    transform: rotate(90deg);
}

.dataClientContainer {
    max-width: 360px;
}

.modal-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    overflow: hidden;
}

.modal-img > img {
    max-height: 509px;
    height: 100%;
}

.modalData {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form {
    margin-top: 20px;
}

.form input,
.form .inputCustom input {
    background-color: #ffffff !important;
    box-sizing: border-box;
    border-radius: 0;
    width: 100%;
    font-size: 20px;
    line-height: 1;
    border: none !important;
    border-radius: 0 !important;
    border-bottom: 1px solid #455466 !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important
}
.form input:focus{
    outline: none !important;
    border-radius: 0 !important;
    border-bottom: 2px solid #1976d2 !important;
}

.form .inputCustom {
    margin-top: 16px;
}
.form .inputCustom.error {
    margin-top: 32px;
}

.btnSend {
    display: block;
    width: 50%;
    height: 35px;
    background: #ff6600;
    border: 1px solid #ff6600;
    border-radius: 3px;
    color: #fff;
    margin: 26px auto;
    cursor: pointer;
}

.label-input {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #455466;
}

#name {
    padding: 5px;
    margin-bottom: 16px;
}

#name::-webkit-input-placeholder:after {
    content: '*';
    color: darkred;
    font-size: 1.5rem;
}

#name.errorName::-webkit-input-placeholder{
    color: #e57373;
} 

.errorInput {
    color: #e57373;
    height: 4px;
    margin-top: -16px;
    margin-bottom: 16px;
    margin-left: 4px;
}

.error {
    color: #e57373 !important;
}

.addressBox {
    display: flex;
    flex-direction: column;
}

.addressBox textarea {
    font-size: 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    box-sizing: border-box;
    width: 100%;
    font-size: 20px;
    line-height: 1;
    border: none;
    border-bottom: 1px solid #455466 !important;
}

.addressBox textarea:focus {
    outline: none !important;
    border-bottom: 2px solid #1976d2 !important;
}

.InfoAboutConnect {
    background: #fff;
    padding: 10px;
    max-width: 400px;
    position: absolute;
    z-index: 3;
    top: 55px;
    right: 15px;
}

.error > .PhoneInput {
    border: 2px solid red !important;
}

.errorName {
    border: 2px solid red !important;
}

.modalContainer{
    display: flex
}

.sendBtnContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 28px;
}

.sendBtnContainer > button {
    margin: 0;
    
}

.sendBtnContainer > button:first-child{
    background-color: transparent;
    border: 1px solid transparent;
    color: #1c2028;
    padding: 0 24px;
}

.sendBtnContainer > button:first-child:hover{
    color: orange
}

.sendBtnContainer > button:nth-child(2){
    background-color: #1c2028;
    border: 1px solid #1c2028;
    max-width: 142px;
}

.sendBtnContainer > button:nth-child(2):hover{
    background-color: #ff6000;
    border: 1px solid #ff6000;
}

@media (max-width: 768px){
    .modal-img {
        display: none
    }
    .modalData {
        width: 100%;
    }
    .content {
        height: initial;
        padding: 24px;
    }
    h2 {
        text-align: center;
    }
    h2,
    .form input,
    .inputCustom input,
    .form .inputCustom .startNumber {
        font-size: 16px !important;
    }
    .form .inputCustom .startNumber {
        top: 28px;
        left: 10px;
    }
    .label-input {
        font-size: 16px;
    }
    .form .addressBox textarea {
        font-size: 16px;
    }
}
