.contacts {
    border-radius: 3px;
    padding: 6px;
    bottom: 2rem;
    position: absolute;
    margin: 10px 0 0 15px;
    width: 11.5rem;
    border: 1px solid #ff6600;
    background: #ff6600;
    cursor: pointer;
    left: -0.4rem;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 4px;
    font-weight: 500;
    color: #fff;
}

.contacts:hover {
    box-shadow: 0px 0px 6px #8198b4;
}

.blockNumber {
    position: absolute;
    border-radius: 3px;
    width: 100%;
    max-width: 11.5rem;
    height: 100px;
    background: white;
    text-align: center;
    flex-direction: column;
    display: flex;
    justify-content: space-around;
    border: 1px solid #b7b7ab;
    font-family: sans-serif;
    bottom: 80px;
    left: 14px;
    z-index: 100;
}

.blockNumber a {
    color: #000;
    text-decoration: none;
    padding: 4px;
}

.blockNumber a:hover {
    text-decoration: underline;
    cursor: pointer;
}
