.inputCustom {
    width: 100%;
    position: relative;
       

}
.inputCustom input {
    box-sizing: border-box;
    border-radius: 6px;
    width: 100%;
    font-size: 20px;
    line-height: 1;
    border: 1px solid #455466 !important;
}

.inputCustom .label-input {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #455466;
}
.inputCustom .startNumber {
    position: absolute;
    top: 28px;
    font-size: 20px;
    left: 5px;
    color: #455466;
}
.inputCustom .errorInput {
    color: #e57373;
    height: 4px;
    margin-top: 4px;
    margin-left: 4px;
}
.required {
    margin-left: 4px;
    color: #e57373;
}
.PhoneInput {
    padding: 5px 55px;
    margin:  0 0;
    font-size: 20px;
    color: #455466;
}

.phoneInput {
    border: none !important;
}
.phoneInput .PhoneInput {
    padding: 5px 50px;
    font-size: 14px;
    margin: 8px 0 0;
}
.phoneInput .label-input {
    font-size: 14px;
    padding-bottom: 8px;
}
.phoneInput .startNumber {
    font-size: 14px;
    top: 31px;
}


@media screen and (max-width: 320px) {
    .inputCustom .label-input {
        font-size: 18px;
    }
    .inputCustom .startNumber {
        top: 44px;
    }
}
