.cover {
    position: absolute;
    z-index: 1;
    top: 0px;
}

.btnMenu {
    border-radius: 3px;
    padding: 9px;
    top: 5px;
    position: absolute;
    margin: 0.5rem 0 0 1rem;
    width: 12.5rem;
    border: 1px solid #FF6600;
    background: #FF6600;
    cursor: pointer;
    left: -0.4rem;
    color: #fff;
    font-weight: bold;
    letter-spacing: 4px;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.dropDown-districts {
    background-color: #fff;
    border: 1px solid #999;
    border-radius: 6px;
    padding: 8px;
    position: absolute;
    left: 12px;
    top: 60px;
    width: 12.5rem;
    max-height: 55vh;
    overflow-y: auto;
}
.dropDown-districts div {
    padding: 5px 30px;
}
.dropDown-districts div:hover {
    background-color: #ff6600c9;
    cursor: pointer;
}
.dropDown-districts div.type {
    padding: 5px 10px;
}

@media screen and (max-width: 648px) {
    .btnMenu {
        top: 45px
    }
    .dropDown-districts {
        top: 100px
    }
}


/* @media screen and (max-width: 648px) {
    .btnMenu {
        top: 50px
    }
    .dropDown-districts {
        top: 100px
    }
} */