.statusNotification {
    margin: 0 0 0 12px;
    font-weight: 600;
    font-size: 21px;
    z-index: 3;
}

.statusNotification_success,
.notification_success {
    color: #28c76f;
}

.statusNotification_error,
.notification_error {
    color: #ff4819;
}

.statusNotification_warning,
.notification_warning {
    color: #ff9f43;
}

.statusNotification_info,
.notification_info {
    color: #3383f9;
}

.Toastify__progress-bar {
    background-color: #d5e5fa !important;
}
.Toastify__progress-bar--success {
    background-color: #28c76f !important;
}
.Toastify__progress-bar--error {
    background-color: #ff4819 !important;
}
.Toastify__progress-bar--warning {
    background-color: #ff9f43 !important;
}
.Toastify__progress-bar--info {
    background-color: #3383f9 !important;
}

.notification .textNotification {
    margin-top: 5px;
    font-size: 15px;
    color: #6e7c93;
    text-align: center;
}

.Toastify__toast-container--top-right {
    top: 50% !important;
    right: 50% !important;
}

.Toastify__toast {
    background: #ffffff !important;
    box-shadow: 0px 0px 68px 100px rgba(34, 60, 80, 0.2) !important;
    border-radius: 12px !important;
    width: 100%;
    max-width: 275px !important;
    padding: 24px !important;
    color: black !important;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 9999;
}

@media screen and (max-width: 640px) {
    .Toastify__toast {
        left: calc(50% - 137px);
    }
}

.Toastify__close-button {
    color: #3383f9 !important;
    position: relative !important;
    top: -10px !important;
}

.Toastify__toast-body {
    color: black;
}
