.searchGoogleMap {
    box-sizing: border-box;
    border: 1px solid transparent;
    width: 374px;
    height: 32px;
    padding: 0 12px;
    border-radius: 3px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    font-size: 14px;
    outline: none;
    text-overflow: ellipses;
    position: absolute;
    right: 12px;
    top: 12px;
    margin-left: -120px;
}

.spinnerContainer{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}
div[role='dialog']{
    padding-top: 0 !important;
}
/* div[title='DIANET ISP']{
    width: 50px !important;
    height: 50px !important;    
    overflow: hidden;
} */
@media screen and (max-width: 648px) {
    .searchGoogleMap {
        left: 12px;
        margin-left: 0;
    }
}

@media screen and (max-width: 375px) {
    .searchGoogleMap {
        width: 93%;
    }
}
